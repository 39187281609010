const idPortalApi = "cf3f45cb0da5e73a35983a96e1d739ad8e6b0d61";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmsantoamarodomaranhao.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsantoamarodomaranhao.ma.gov.br",
  NomePortal: "Santo Amaro",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/gDtzAKEizFLB127W8`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15944.0472886858!2d-43.25492290635987!3d-2.50298598167686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f174ba3c64a6d9%3A0xf0a2f29bc35f40a0!2sSanto%20Amaro%20do%20Maranh%C3%A3o%2C%20MA!5e0!3m2!1spt-BR!2sbr!4v1722005624687!5m2!1spt-BR!2sbr",
};
